




















































































































































































































































































































































































































































































import AuthService from "@/services/AuthService";
import EventService from "@/services/EventService";
import TimetableService from "@/services/TimetableService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Registration extends Vue {
  private item: any = {};
  private items: any = [];
  private guests: any[] = [];
  private userGuests: any[] = [];
  private hasError: boolean = false;
  private noEvent: boolean = false;
  private error: string = "";
  private confirm: boolean = false;
  private confirmed: boolean = false;
  private consent: boolean = false;
  private uuid: string = router.currentRoute.params.event || "";
  private token: string = router.currentRoute.params.token || "";
  private date: string = (router.currentRoute.query.date as string) || "";
  private location: string =
    (router.currentRoute.query.location as string) || "";

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  validGuest(i: number = -1) {
    let guest = i < 0 ? this.guests[this.guests.length - 1] : this.guests[i];

    if (this.item === "NAME") {
      return this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "ADDRESS") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.address.street) &&
            this.state(guest.address.zip) &&
            this.state(guest.address.city)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "CONTACT") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.contact.phone)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else if (this.item.registrationMode === "ADDRESS_CONTACT") {
      return i === 0
        ? this.state(guest.firstName) &&
            this.state(guest.lastName) &&
            this.state(guest.address.street) &&
            this.state(guest.address.zip) &&
            this.state(guest.address.city) &&
            this.state(guest.contact.phone)
        : this.state(guest.firstName) && this.state(guest.lastName);
    } else {
      return true;
    }
  }

  validGuests(guests: any) {
    return (
      guests.filter((g: any, i: number) => !this.validGuest(i)).length == 0
    );
  }

  toDate(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  back() {
    router.back();
  }

  next() {
    router.push({ name: "request" });
  }

  confirmRegistration() {
    TimetableService.verify(this.guests).then((response) => {
      this.guests = response.data;
      this.confirm = true;
    });
  }

  unconfirm() {
    this.confirm = false;
  }

  add() {
    if (this.isLoggedIn()) {
      AuthService.user().then((response) =>
        this.guests.push({
          lastName: this.guests.length > 0 ? this.guests[0].lastName : "",
          address: { city: "Frankfurt" },
          contact: { email: response.data.email },
          show: false,
        })
      );
    } else {
      this.guests.push({
        lastName: this.guests.length > 0 ? this.guests[0].lastName : "",
        address: { city: "Frankfurt" },
        contact: {},
        show: false,
      });
    }
  }

  send() {
    let r;

    if (this.location) {
      r = TimetableService.registration(
        this.uuid,
        moment(new Date()).format("YYYY-MM-DD"),
        this.guests
      );
    } else if (this.uuid) {
      r = TimetableService.registration(this.uuid, this.date, this.guests);
    } else if (this.token) {
      this.item.guests = this.guests;
      r = TimetableService.updateRegistration(this.token, this.item);
    } else {
      return;
    }

    r.then((response) => {
      if (this.location) {
        this.confirmed = true;
      } else {
        router.push({ name: "home" });
      }
    });
  }

  cancel() {
    if (this.token) {
      TimetableService.cancelRegistration(this.token).then((response) =>
        router.push({ name: "home" })
      );
    } else {
      EventService.remove(this.item.registrations[0].uuid).then((response) =>
        router.push({ name: "home" })
      );
    }
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  download() {
    TimetableService.download().then((response: any) => {
      var downloadURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const anchor = document.createElement("a");
      anchor.download = "Anmeldung_Gottesdienst_Datenschutz.pdf";
      anchor.href = downloadURL;
      anchor.target = "_blank";
      anchor.click();
    });
  }

  register(i: number) {
    this.item = this.items[i];
    this.uuid = this.item.event.uuid;
    this.items = [];
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    if (this.isLoggedIn()) {
      AuthService.userGuests().then((response) => {
        this.userGuests = response.data;
        if (this.userGuests.length === 0) {
          this.add();
        }
      });
    } else {
      this.add();
    }

    if (this.location) {
      TimetableService.nextevent(this.location)
        .then((response) => {
          if (response.data.length === 0) {
            this.item = {};
            this.guests = [];
            this.noEvent = true;
          } else if (response.data.length === 1) {
            this.item = response.data[0];
            this.uuid = this.item.event.uuid;
          } else {
            this.item = {};
            this.items = response.data;
            this.uuid = "";
          }
        })
        .catch((err) => console.log(err));
    } else if (this.uuid) {
      TimetableService.get(this.uuid, this.date)
        .then((response) => {
          this.item = response.data;
          if (this.item.registrations[0]?.guests) {
            this.guests = this.item.registrations[0]?.guests;
          }
        })
        .catch((err) => console.log(err));
    } else if (this.token) {
      TimetableService.load(this.token)
        .then((response) => {
          if (response.status === 204) {
            this.item = {};
            this.guests = [];
          } else {
            this.item = response.data;
            this.guests = this.item.guests;
          }
        })
        .catch((err) => console.log(err));
    }
  }
}
